import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import { useSortableTable } from "../useSortableTable";

const Table = ({ data, columns, filterItems }) => {
  const [tableData, handleSorting] = useSortableTable(data, columns);
  const itemPerRow = 20;
  const [next, setNext] = useState(itemPerRow);

  useEffect(() => {
    postFrameDimension();
  });

  const handleMoreItems = () => {
    setNext(next + itemPerRow);
    postFrameDimension();
  };

  const postFrameDimension = () => {
    if (window && window.parent) {
      const root = document.getElementById('root');
      window.parent.postMessage({frameHeight: root.offsetHeight}, '*');
    }
  };

  return (
    <>
      <table className="table">
        <TableHead {...{ columns, handleSorting, filterItems }} />
        <TableBody {...{ columns, tableData, next, filterItems }} />
      </table>

      <Button className="load-more-btn" onClick={ handleMoreItems }>
        Load more course outlines +
      </Button>
    </>
  );
};

export default Table;
