import COOL1 from './assets/course-json/cool1.json';
import PreCool from './assets/course-json/precool.json';
import LawJustice from './assets/course-json/law-justice.json';
import Engineering from './assets/course-json/engineering.json';
import ADA from './assets/course-json/ada.json';
import Science from './assets/course-json/science.json';
import COOL2 from './assets/course-json/cool2.json';
import UnswCanberra from './assets/course-json/unsw-canberra.json';
import Online from './assets/course-json/online.json';

let CoursesData = [...new Set([
  ...COOL1,
  ...PreCool,
  ...LawJustice,
  ...Engineering,
  ...ADA,
  ...Science,
  ...COOL2,
  ...UnswCanberra,
  ...Online
])];

export default CoursesData;
