import { useState, useEffect } from "react";
import React from "react";
const TableBody = ({ tableData, columns, next, filterItems }) => {
  const [filteredData, setFilteredData] = useState([]);

  let initialized = false

  useEffect(() => {
    if (!initialized) {
      let oldtableData = [...tableData];

      const filteredAllCourses = oldtableData.filter(course => {

        if (filterItems.Code) {
          const courseCode = course.Code.toString().toLowerCase();
          if (!courseCode.includes(filterItems.Code.toLowerCase())) return false;
        }

        if (filterItems.Name) {
          const courseName = course.Name.toString().toLowerCase();
          if (!courseName.includes(filterItems.Name.toLowerCase())) return false;
        }

        if (filterItems.Level && course.Level !== filterItems.Level) {
          return false
        }

        if (filterItems.School && course.School !== filterItems.School) {
          return false
        }

        if (filterItems.Year && course.Year !== filterItems.Year) {
          return false
        }

        if (filterItems.Term && course.Term !== filterItems.Term) {
          return false
        }

        return true
    })
    setFilteredData(filteredAllCourses);
    initialized = true
  }
  }, [filterItems, tableData]);


  return (
    <tbody>
      {filteredData?.slice(0, next)?.map((data, i) => {
        return (
          <tr key={i}>
            {columns.map(({ accessor }) => {
              const tData = data[accessor] ? data[accessor] : "——";
              const renderTData = accessor === 'Code' || accessor === 'Name'
                ? <a href={"/documents/" + data.Folder + "/" + data.FileName} target="_blank">{data[accessor]}</a>
              : tData;
              return <td key={accessor}>{ renderTData }</td>;
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
