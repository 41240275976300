import React from "react";
import { useState, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CoursesData from "./CoursesData";
import Table from "./components/Table";

function Home() {
  const [Code, setCode] = useState('');
  const [Name, setName] = useState('');
  const [filterItems, setFilterItems] = useState([]);
  const pageContainer = useRef(null);
  const addToFilterFields = (target) => {
    const fieldName = target.getAttribute("aria-label");
    const value = target.value;
    setFilterItems({ ...filterItems, [fieldName]: value });
  }

  const uniqueBy = (arr, prop) => {
    return arr.reduce((a, d) => {
      if (!a.includes(d[prop])) { a.push(d[prop]); }
         return a;
      }, []).sort((ua, ub) => (prop === 'Year') ? ub - ua : ((ub < ua) ? 1 : -1));
      // Descending order for Year, the rest are A-Z sorting.
  };

  const clearFilters = () => {
    setCode('');
    setName('');
    setFilterItems([]);

    const theads = document.querySelector('.up, .down');
    theads.setAttribute('class', 'default');
  }

  const handleChangeInputName = (e) => {
    addToFilterFields(e.target);
    setName(e.target.value);
  };

  const handleChangeInputCode = (e) => {
    addToFilterFields(e.target);
    setCode(e.target.value);
  };

  const columns = [
    { label: "Code", accessor: "Code", sortable: true },
    { label: "Name", accessor: "Name", sortable: true },
    { label: "Level", accessor: "Level", sortable: true },
    { label: "School", accessor: "School", sortable: true },
    { label: "Term", accessor: "Term", sortable: true },
    { label: "Year", accessor: "Year", sortable: true, sortbyOrder: "desc"},
  ];

  return (
    <Container id="mainContainer" ref={pageContainer}>
      <Form key="formFilters">
        <Row className="mb-3">
          <Col>
            <Form.Control
              value={Name}
              onChange={handleChangeInputName}
              placeholder="Search course name"
              aria-label="Name"
            />
          </Col>
          <Col>
            <Form.Control
              value={Code}
              onChange={handleChangeInputCode}
              placeholder="Search course code"
              aria-label="Code"
            />
          </Col>
        </Row>
        <Row className="mb-3">
        <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Year</Form.Label>
            <Form.Select onChange={e => addToFilterFields(e.target)} placeholder="Select year" aria-label="Year">
              <option value="">Select year</option>
              { uniqueBy(CoursesData, 'Year').map((item, i) => (
                <option key={i} value={item}>{item}</option>
              ))}
            </Form.Select>
          </Form.Group>
          </Col>
          <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Study Level</Form.Label>
            <Form.Select onChange={e => addToFilterFields(e.target)} placeholder="Select level" aria-label="Level">
              <option value="">Select study level...</option>
              { uniqueBy(CoursesData, 'Level').map((item, i) => (
                <option key={i} value={item}>{item}</option>
              ))}
            </Form.Select>
          </Form.Group>
          </Col>
          <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>School</Form.Label>
              <Form.Select onChange={e => addToFilterFields(e.target)} placeholder="Select school" aria-label="School">
                <option value="">Select school...</option>
                { uniqueBy(CoursesData, 'School').map((item, i) => (
                  <option key={i} value={item}>{item}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Terms</Form.Label>
              <Form.Select onChange={e => addToFilterFields(e.target)} placeholder="Select term" aria-label="Term">
                <option value="">Select term</option>
                { uniqueBy(CoursesData, 'Term').map((item, i) => (
                  <option key={i} value={item}>{item}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      <Row className="justify-content-md-end">
        <Button className="clear-filter-btn" variant="link" onClick={clearFilters} type="reset">Clear filters</Button>
      </Row>
      </Form>

      <Row id="filterInformation" key="dynamicFilterResults">
        {Object.entries(filterItems).map(([key, value]) => {
          if (value) {
            return (
              <span key={key}>
                {key}: {value}
              </span>
            );
          }
        })}
      </Row>

      <Row key="courseResults">
        <Col>
          <Table data={CoursesData} columns={columns} filterItems={filterItems}/>
        </Col>
      </Row>
    </Container>
  );

}

export default Home;
