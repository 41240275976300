import React from 'react';
import logo from './logo.svg';
import './App.css';
import Routing from "./Routing"
import { BrowserRouter } from "react-router-dom";

export const App = () => (



 <div>
  <BrowserRouter>
  <Routing/>
  </BrowserRouter>
 </div>
)
